//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================

// Bootstrap variables
$primary: #0134A1 ;
// $primary: #CD212A ;
// $primary: #19468D; // repove #19468D appin #CD212A
// $primary-dark: #022C6E;
// $secondary: #00A5B8;
$dark-grey: #9F9F9F;
$light-grey: #DDDDDD;
$white: #FFFFFF;
$black: #000000;
